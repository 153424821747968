
import React from 'react'

import Title from '../common/Title' // name change required same name two componets
import { services } from '../data/data'

const Services = () => {
    const pathname = window.location.pathname;
    const heroimg = "../images/serviceshero.jpg"
  
  return (
    <>
    <div className="pageConatiner">
    <div className={`heroImage ${pathname === "/services"?"active":""}`}>
    
    <img src='../images/HERO/serviceshero-min.jpg'alt=''/>
    </div>
    <section className='services'>
        <div className={`container ${pathname === "/services"?"servPage":""}`}>
            
          <Title title='Services' />
          <div className='content grid3'>
            {services.map((item) => (
              <div className='box' data-aos='flip-left'>
                <img className='serviceboximg' src={item.cover} alt=" "/>
                <i>{item.icon}</i>
                <h3>{item.title}</h3>
               
              </div>
            ))}
          </div>
        </div>
      </section>
      </div>
    </>
  )
}

export default Services