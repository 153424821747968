import React from 'react'

const Title = ({title}) => {
  return (
    <>
    <h2 className='headingPage'  data-aos='zoom-in-down'>{title}</h2>
    
    </>
  )
}

export default Title