import React from 'react'

import HeroSlider, { Slide, MenuNav } from "hero-slider";



const bogliasco = "../images/HERO/NISH-min.jpg";
const countyClare = "../images/HERO/HLL.jpg";

const giauPass = "../images/HERO/dreams.jpg";

const Hero = () => {
  return (
    <>
    <div className="slider">
      <HeroSlider
      height={"85vh"}
      autoplay
      controller={{
        initialSlide: 1,
        slidingDuration: 500,
        slidingDelay: 100,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) =>
          console.debug("onAfterSliding(nextSlide): ", nextSlide)
      }}
    >
     

      <Slide
        shouldRenderMask
        label="Crescent Dreams - Trivandrum"
        background={{
          backgroundImageSrc: giauPass
        }}
       
      />
       

      <Slide
        shouldRenderMask
        label="NISH AKKULAM - Trivandrum"
        background={{
          backgroundImageSrc: bogliasco
        }}
      />

      <Slide
        shouldRenderMask
        label="HLL-Life-Care-Limited - Trivandrum"
        background={{
          backgroundImageSrc: countyClare
        }}
      />

     

      <MenuNav />
    </HeroSlider>
    </div>
    
    </>
  )
}

export default Hero