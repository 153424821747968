import React from 'react'
import About from '../pages/About'
import Hero from './Hero'
import Services from './Services'
import { Counter } from '../pages/Counter'
import Projects from '../pages/Projects'
import Testimonials from '../pages/Testimonials'
import Contact from '../pages/Contact'
import TypewriterPage from '../pages/TypewriterPage'
import Clients from './Clients'
import Banner from './Banner'

const Home = () => {
  return (
    <>
    <Hero/>
    <Banner/>
    <Counter />
   
    <Projects />
    <TypewriterPage />
    <Clients />
    <Services />
    <Testimonials />
    {/* <Contact /> */}
    
    </>
  )
}

export default Home