import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Footer from '../common/Footer';
import Header from '../common/Header';
import Navbar from '../common/Navbar';
import Home from '../home/Home';
import ScrollToTop from '../home/ScrollToTop';
import Services from '../home/Services';
import About from './About'
import ClientsPage from './ClientsPage';
import Contact from './Contact';
import Projects from './Projects';
import Testimonials from './Testimonials';
  

const Pages = () => {
  return (
    <>
    
    <Router>
      <ScrollToTop />
    <Navbar />
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route exact path='/about' component={About}/>
          <Route exact path='/services' component={Services}/>
          <Route exact path='/projects' component={Projects}/>  
           {/* change path name portfolio to projects also in nav bar */}
           {/* <Route exact path='/testimonials' component={Testimonials}/>   */}
           <Route exact path='/contact' component={Contact}/>  
           <Route exact path='/clients' component={ClientsPage}/> 
          
        </Switch>
        <Footer />
    
    </Router>
    </>
  )
}

export default Pages