import React from 'react'
import { home } from '../data/data'
import Typewriter from "typewriter-effect"
// import brochure.pdf from '../../assets/brochure'


const TypewriterPage = () => {
  return (
    <>
    <section className='hero'>
     
     {home.map((val,i)=>(
         <div className='heroContainer'>
             <h2 className='fontsize' data-aos='fade-right'>{val.text}</h2>
             <h1>
                 <Typewriter options={{
                     strings:[`${val.name}`,`${val.post}`,`${val.design}`],
                     autoStart:true,
                     loop:true,
                 }}
                 />
             </h1>
             <p data-aos='fade-left'>
             
             CRESCENT CONSTRUCTION COMPANY is one among the pioneers in 
             the construction industry of Kerala with over 30 years of experience. 
             Since our inception in 1991, we have secured a spot as one of the top builders 
             and developers in Trivandrum. We 
             offer building projects,  
             flats, villa projects, commercial complexes and Govt Projects.
             
            
             </p>
             <a href="../brochure.pdf" download="CCBROCHURE">
             <button className='primaryBtn' data-aos='fade-up-right'>
              Download Brochure
              </button>
              </a>
         </div>
     ))}


   </section>
    
    
    </>
  )
}

export default TypewriterPage