import logo from './logo.svg';
import './App.css';
import Pages from './components/pages/Pages'
import Header from './components/common/Header';
import AOS from "aos"
import "aos/dist/aos.css"
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])  
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);
  // Animation
  return (
    <>
    <Pages />
    </>
  );
}

export default App;
