import React from 'react'

import { useHistory } from 'react-router-dom';

const Banner = () => {
    let history = useHistory();
    const handleClick = () => {
        history.push("/contact");
       }
  return (
    <div>
        <>
        <div className="banner" data-aos="flip-left">
          <h1>GET OUR FREE CONSULTATION NOW !</h1>
          
           <button onClick={handleClick} >Contact Us</button>
           
          </div>
        </>
    </div>
  )
}

export default Banner