import React, { useState } from 'react'
import Title from '../common/Title' //change name title to heading , same name 2 elements
// import { paneldata } from '../data/data'


const About = () => {
    
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
      };
  return (
   <>
     <section className='about'>
        <div className='container flex'>
            
              <div className='left' data-aos='fade-down-right'>
                <img src="../images/about_img.webp"
                
                 alt=''/>
                 
              </div>
              <div className='right' data-aos='fade-down-left'>
                <Title title='About Us'/>
                <div className="bloc-tabs">
        <button
          className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(1)}
        >
          Our Company
        </button>
        <button
          className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(2)}
        >
          Our History
        </button>
       
      </div>

      <div className="content-tabs">
        <div
          className={toggleState === 1 ? "content  active-content" : "content"}
        >
          
          
          <p>
          We, Crescent Construction has been a known name in the Building Industry for over a quarter of the century, 
                 both in the domestic and commercial sectors. We provide complete construction service to you 
                 for any of your planned construction works. The dream of having a building is not far away, if we are with you.</p>
                <p>Our business is founded on TRUST AND COMMITMENT. Our mission is to fulfill customer needs with keen and 
                 consistent working, towards timely delivery. With over 30 years of experience,having hard working and 
                 committed workers, Crescent has a proven track record of excellence in every aspect of the construction field.</p>
                 <p>We aim to offer a very customer-focused service, and has many highly successful projects in our kitty.
                 We provide Design-Build, Supervising, Construction Management, General Contracting and Pre-Engineered 
                 Building services throughout the region.</p>
                 <p>It doesn’t matter if you have to build a home or hospital, bridge or road, 
                 mosque or temple, we are there to guide you, supervise you and build it for you.</p>
                <h1>Our Misson</h1>
                <hr />
                 <p>Our mission is to ensure Quality construction, efficient and timely delivery, safe working environment, cost effective and customer satisfaction.

                With a range of multi-disciplinary experience, we are able to tailor fully-integrated and cost-effective solutions for our clients, 
                delivering effective design-and-build services.</p>
                <h1>Our Vision</h1>
                <hr />
                <p>Adhering to the highest standards of our ethics, transparency, integrity and accountability in management.

                 Delivering sustainable value through disciplined management process, emphasizing on current and long term planning and profit optimization.

                Adhering to high quality standard in improving efficiency and reducing cost to provide excellence in all aspects of the business.
          </p>
        </div>

        <div
          className={toggleState === 2 ? "content  active-content" : "content"}
        >
         
          <p>
          The company was established in the 1980’s by Basheer Koya (PWD engineer) and his brother Mr.Sada liyakhath. The desire to engage in projects on their own led them to start a construction company ‘crescent constructions’. With that aim, he took a 5 year leave from service and started undertaking projects. Kollam Kesava Memorial Hall, Municipal library, reserved police camp, Kallada Bridge were the works completed during this induction period.

           In the 1990’s Basheer Koya’s son Anas Babu completed his Engineering degree and joined the company. With the newfound confidence, Basheer Koya took voluntary retirement from service and started developing his company.

           First construction work under Anas Babu’s assistantship was Maharani Shopping Complex Kollam Corporation. Later on, he took some bridge works and in 1998 he got a class license for construction works.

          In 2005 Moonnattumukku Bridge across T.S. Canal in Thiruvallam was completed and this earned us appreciation from the government and elsewhere. That was the turning point for our company and from there we never looked back. Our ever-increasing satisfied clientele also includes the Indian Space Research Organization (ISRO).
          </p>
          <ul>
               <li>Construction of Assembly and Clean Room Building at I.S.R.O. Valiyamala</li>
               <li>Integrated Simulation Test Facility Building at I.S.R.O., TERLS, Thumba</li>
               <li>Building and Services (Civil & PH works) at I.S.R.O. Thumba. etc  are the major works we did for ISRO.</li>
             </ul>
  <p>We have completed works for Kerala Police Corporation, Kerala state road Transport Corporation, Hindustan latex, central public works department, University of Kerala, Govt of Kerala etc.</p>

            
             <p>We are visionaries in our approach, Unconstrained in our vision – young, growing, dynamic and future driven.</p>
             <p>Our vision to be the premier company serving a full spectrum of facilities to our clients and opportunities for our employees remains steadfast, as is our dedication to core values. We are a leading building firm, providing full construction and facilities-related services ranging from sustainable buildings to the latest in construction technology for clients across various markets. With our values, philosophy and vision firmly in place, we are confident of sustained profitability in the future.</p>
        </div>

        
      </div>
                
                   
              </div>
            

        </div>
     </section>
   </>
  )
}

export default About