import React, { useState } from 'react'
import "./navbar.css"
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [ clicked,setClicked] = useState(false)

    const handleClick = ()=>{
        
        setClicked(!clicked)
        console.log(clicked)
    }
  return (
   <>
   <nav>
    <a href='#'>
        <div className="logoheader">
        <img src="../images/CCC Logo.png" alt="" ></img>
        </div>
    </a>
    <div className={clicked?"navlinks activenav":"navlinks"}>
        <ul 
        
        >
            <li>
                
                <a href=''><Link id='navlink1' to="/" onClick={()=>setClicked(false)} >Home</Link></a>
               
                <a href=''><Link id='navlink1' to="/about" onClick={()=>setClicked(false)} >About Us</Link></a>
                <a href=''><Link id='navlink1' to="/services" onClick={()=>setClicked(false)} >Services</Link></a>
                <a href=''><Link id='navlink1' to="/projects" onClick={()=>setClicked(false)} >Projects</Link></a>
                <a href=''><Link id='navlink1' to="/clients" onClick={()=>setClicked(false)} >Clients</Link></a>
                <a className='phnicn' href='#'><Link to="/contact" ><LocalPhoneIcon color="primary"/></Link></a>
                <a href=''><Link id='navlink1' to="/contact" onClick={()=>setClicked(false)} >Contact Us</Link></a>
            </li>
        </ul>
    </div>
    <div id="mobile" onClick={()=>handleClick()}>
        <i id='bar' className={clicked?"fa fa-times":"fa fa-bars"}></i>
        
    </div>
   </nav>
   
   </>
  )
}

export default Navbar