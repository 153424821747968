import React from 'react'
import { clients } from '../data/data'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Title from '../common/Title' 
import Testimonials from './Testimonials';

const ClientsPage = () => {
  return (
    <>
    <div className="section">
    <div className="clients">
        <div className="container">
         <Title title="Clients" />
         <div className="clientscardcontainerpage">
        {clients.map((item,i)=>(
            <div className="clientcards">
            <Card sx={{ width: 250, height:220 }} style={{backgroundColor: "#379683"}}>
            <CardActionArea>
        <CardMedia
        className="media-img"
          component="img"
          height="140"
          image={item.img}
          alt="client"
        />
        <CardContent>
          <Typography gutterBottom variant="body2" component="div" >
            <div className="carddesc">
           {item.title}
           </div>
          </Typography>
          
        </CardContent>
      </CardActionArea>
    </Card>
            </div>
        ))}
        </div>
    </div>
    </div>
    </div>
    <Testimonials />
    
    </>
  )
}

export default ClientsPage