import { FormatQuote } from '@mui/icons-material'
import React from 'react'
import { testimonials } from '../data/data'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Title from '../common/Title';

const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
  return (
    <>
     <div className="testheading">
    <Title title="Testimonials" />
    </div>
      <div className="testimonials hero">
     
        <div className="container">
        
        <Slider {...settings}>
            {testimonials.map((val)=>(
                <div className="box">
                    <i  data-aos='zoom-out-up'>
                        <FormatQuote />
                    </i>
                        <p data-aos='zoom-out-down'>{val.text}</p>
                        <h3 data-aos='zoom-out-left'>{val.name}</h3>
                        <label data-aos='zoom-out'>{val.post}</label>

                    
                </div>
            ))}
            </Slider>
        </div>
      </div>
    </>
  )
}

export default Testimonials