import React from 'react'
import { contact, social } from '../data/data'
import { FaFacebookSquare,FaInstagram,FaYoutube,FaTwitterSquare } from 'react-icons/fa'
import { FiMapPin,FiPhoneCall,FiMail } from 'react-icons/fi'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
       <footer>
      <div className="containerfooter">
        <div className="sec aboutus">
          <h2>About Us</h2>
          <img src='../images/CCC Logo.png' height={45} width={135}/>
          <p>Structural works as international construction services company and is 
            a leading builder in diverse and numerous market segments.</p>
            <ul className='social'>
              <li><a href='https://www.facebook.com/CrescentConstructionAndContracting'
              target='_blank'
              ><FaFacebookSquare /></a></li>
              <li><a href='#'><FaInstagram /></a></li>
              <li><a href='#'><FaYoutube /></a></li>
              <li><a href='#'><FaTwitterSquare /> </a></li>
            </ul>
        </div>
        <div className="sec branch">
          <h2>Our Branch</h2>
          <ul>
            <li><a href='#'>Crescent Arabia,<br/> Saudi Arabia</a></li>
          </ul>
        </div>
        <div className="sec information">
          <h2>Information</h2>
          <ul>
            <li><a href=''><Link to ="/">Home</Link></a></li>
            <li><a href=''><Link  to="/about">About Us</Link></a></li>
            <li><a href=''><Link  to="/services">Services</Link></a></li>
            <li><a href=''><Link  to="/about">Company History</Link></a></li>
            <li><a href=''><Link to="/">Our Partners</Link></a></li>
            <li><a href=''><Link to="/">Careers</Link></a></li>
            <li><a href=''><Link  to="/projects">Projects</Link></a></li>
            <li><a href=''><Link to="/">Core Values</Link></a></li>
            <li><a href=''><Link  to="/contact">Contact</Link></a></li>
          </ul>
        </div>
        <div className="sec contactfooter">
          <h2>Contact Us</h2>
          <ul className='info'>
            <li>
              <span><FiMapPin/></span>
              <span>Vallakadavu<br/>
              Trivandrum Kerala<br/>
              India</span>
            </li>
            <li>
              <span><FiPhoneCall/></span>
              <p><a href="tel:+917736044483">+91 7736044483</a><br/>
              <a href="tel:+919249544482">+91 9249544482</a></p>

            </li>
            <li>
              <span><FiMail/></span>
              <p><a href="mailto:crescentcontractingcompany@gmail.com">crescentcontractingcompany@gmail.com</a><br/>
              <a href="mailto:crescentcctvm@gmail.com">crescentcctvm@gmail.com</a></p>

            </li>
          </ul>
        </div>
      </div>
    </footer>
    <div className="copyright">
      <p>Copyright © 2023.All Rights Reserved</p>
    </div>
  </>
  )
}

export default Footer