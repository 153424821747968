import { Settings, CropRotate, ViewInAr, PieChart, Code, BarChart, CloudOutlined, FavoriteBorder, Public, PersonOutlined, AddLocationAltOutlined, PhoneIphone, EmailOutlined, Facebook, Twitter, Instagram, YouTube } from "@mui/icons-material"



export const navlink = [
    {
      url: "/",
      text: "Home",
      
    },
    {
      url: "/about",
      text: "About Us",
      
    },
    {
      url: "/services",
      text: "Services",
      
    },
    {
      url: "/projects",
      text: "Projects",
      
    },
    
    {
      url: "/clients",
      text: "Clients",
      
    },
    
   
  ]

  export const home = [
    {
      text: "We're",
      name: "The Crescent Construction",
      post: "Builders",
      design: "advocates for our clients",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    },
  ]

  export const services = [
    {
      id: 1,
      icon: <Settings />,
      title: "Annual maintenance and Renovation works.",
      desc: "Lorem Ipsum simply text of the printing and type setting industry when an unknown printing simply",
      cover:"../images/services/homesafe.jpg"
    },
    {
      id: 2,
      icon: <CropRotate />,
      title: "Infrastructure development and Construction of multi storied buildings.",
      desc: "Lorem Ipsum simply text of the printing and type setting industry when an unknown printing simply",
      cover:"../images/services/multi.jpg "
    },
    {
      id: 3,
      icon: <ViewInAr />,
      title: "Site selection and finalization, Development of design, Schedule & Costing.",
      desc: "Lorem Ipsum simply text of the printing and type setting industry when an unknown printing simply",
      cover:"../images/services/service3.jpg "
    },
    {
      id: 4,
      icon: <PieChart />,
      title: "Home construction and Buying guidance.",
      desc: "Lorem Ipsum simply text of the printing and type setting industry when an unknown printing simply",
      cover:"../images/services/service4.jpg "
    },
    {
      id: 5,
      icon: <Code />,
      title: "Construction materials supply.",
      desc: "Lorem Ipsum simply text of the printing and type setting industry when an unknown printing simply",
      cover:"../images/services/tipper-min.jpg "
    },
    {
      id: 6,
      icon: <BarChart />,
      title: "Construction equipment for rental.",
      desc: "Lorem Ipsum simply text of the printing and type setting industry when an unknown printing simply",
      cover:"../images/services/roller.jpg"
    },
  ]
  export const project = [
    {
      id: 1,
      icon: <Public />,
      num: "15",
      title: "Area Developed(Lakhs sq.ft.)",
    },
    {
      id: 2,
      icon: <FavoriteBorder />,
      num: "75",
      title: "Happy Clients",
    },
    {
      id: 3,
      icon: <CloudOutlined/>,
      num: "25",
      title: "Central Govt Projects",
    },
    {
      id: 4,
      icon: <PersonOutlined />,
      num: "40",
      title: "State Govt Projects",
    },
  ]
  export const projects = [
    {
      id: 1,
      cover: "../images/projects/Nish2-min.jpg",
      name: "Trivandrum",
      category: "Complete",
      title: "National Institute Of Speech & Hearing - Trivandrum",
      featured:"true",
    },
    {
      id: 2,
      cover: "../images/projects/mosque-min.jpg",
      name: "Trivandrum",
      category: "Complete",
      title: "Juma Masjid Kazhakoottam - Trivandrum",
      featured:"true",
    },
    {
      id: 3,
      cover: "../images/projects/credreams2.jpg",
      name: "Trivandrum",
      category: "ongoing",
      title: "Crescent Dreams - Trivandrum",
      featured:"true",
    },
   
  
    {
      id: 4,
      cover: "../images/projects/HLL.jpg",
      name: "Trivandrum",
      category: "Complete",
      title: "Hll Life-Care-Limited - Trivandrum",
      featured:"true",
    },
    {
      id: 5,
      cover: "../images/projects/haans2-min.jpg",
      name: "Trivandrum",
      category: "Complete",
      title: "Haans Apartments - Trivandrum",
      featured:"true",
    },
    {
      id: 6,
      cover: "../images/projects/Central-Public-Works-Department-3.jpg",
      name: "Trivandrum",
      category: "Complete",
      title: "80 Bedded Hostel - Trivandrum (CPWD)",
      featured:"true",
    },
    {
      id: 7,
      cover: "../images/projects/District Planning Office Alappuzha.jpg",
      name:"Alappuzha",
      category: "Complete",
      title: "District Planning Office - Alappuzha, Kerala",
    },
    
   
    {
      id: 8,
      cover: "../images/projects/Hostel for Elite Sports Persons (Men)-min (1).jpg",
      name: "Trivandrum",
      category: "Complete",
      title: "60 Bedded Men's Elite Hostel (CPWD)",
    },
    {
      id: 9,
      cover: "../images/projects/Hostel for Elite Sports Persons (Women)-min.jpg",
      name: "Trivandrum",
      category: "Complete",
      title: "40 Bedded Woman's Elite Hostel (CPWD)",
    },
    {
      id: 10,
      cover: "../images/projects/Sports Sceince Center-min.jpg",
      name: "Trivandrum",
      category: "Complete",
      title: "Sports Amenities Center - Trivandrum (CPWD)",
    },
    {
      id: 11,
      cover: "../images/projects/Chengannur KSRTC.jpeg",
      name: "Alappuzha",
      category: "Complete",
      title: "KSRTC Bus Bay - Chengannur, Alappuzha",
    },
    {
      id: 12,
      cover: "../images/projects/Govt. VHS & HSS Poovachal, Trivandrum-min.JPG",
      name: "Trivandrum",
      category: "Complete",
      title: "Govt. VHS School Poovachal - Trivandrum",
    },
    
    {
      id: 13,
      cover: "../images/projects/Central-Public-Works-Department-1.jpg",
      name: "Trivandrum",
      category: "Complete",
      title: "Science Center - Trivandrum",
    },
    {
      id: 14,
      cover: "../images/projects/anayra2-min-min.jpg",
      name: "Trivandrum",
      category: "Complete",
      title: "New KSRTC Bus Stand - Anayara, Trivandrum",
    },
    {
      id: 15,
      cover: "../images/projects/attingalgovtschool-min-min.JPG",
      name: "Trivandrum",
      category: "ongoing",
      title: "Attingal Govt.School - Trivandrum",
    },
    {
      id: 16,
      cover: "../images/projects/Govtschoolnavaikulam.jpg",
      name: "Trivandrum",
      category: "Complete",
      title: "Navaikulam Govt.School - Trivandrum",
    },
    {
      id: 17,
      cover: "../images/projects/sdma-min.jpg",
      name: "Trivandrum",
      category: "Complete",
      title: "Kerala State Disaster Management Authority - Trivandrum",
    },
  ]

  export const testimonials = [
    {
      id: 1,
      text: "“I would like to thank you again for the hard work you and your team have put into the successful completion of our Building. Your strong attention to keep and improving the schedule is the major reason for the timeliness of completion. You have brought a great deal of experience that has been valuable in a project of this size and is a key reason for its success. I am extremely proud of the crescent contraction Company and have enjoyed working with you to get it built.”",
      // image: "./images/testimonials/team-1.png",
      name: "NISH",
      post: "Client",
    },
    {
      id: 2,
      text: "“Your organization has performed professionally…their commitment to quality and the extraordinary efforts in ensuring the ultimate success of this project was wonderful. I wouldn’t hesitate to recommend crescent contraction Company for anything. I look forward to working with your organization again soon.”",
      // image: "./images/testimonials/team-2.png",
      name: "Haans Apartment",
      post: "Client",
    },
  
  ]
  export const contact = [
    {
      icon: <AddLocationAltOutlined />,
      text1: " Vallakadavu",
      text2: "Trivandrum",
    },
    {
      icon: <PhoneIphone />,
      text1: " +91 471-2504480 ",
      text2: "+91 7736044483",
    },
    {
      icon: <EmailOutlined />,
      text1: "crescentcontractingcompany@gmail.com",
      text2: "",
    },
  ]
  export const clients = [
    {
      id: 4,
      img:"../images/isrologo2.jpg",
      title: "ISRO",
    },
    {
      id: 4,
      img:"../images/cpwdlogo.png",
      title: "Central Public Works Department",
    },
    {
      id: 3,
      img:"../images/hlllogo.jpg",
      title: "Hindustan Latex Limited",
    },
    {
      id: 4,
      img:"../images/ksrtclogo.jpg",
      title: "Kerala State Road Transport Corp.",
    },
    {
      id: 4,
      img:"../images/nishlogo.png",
      title: "National Institue Of Speech And Hearing",
    },
    {
      id: 4,
      img:"../images/kpwdlogo.jpg",
      title: "Kerala State Public Works Department ",
    },
    {
      id: 4,
      img:"../images/kphclogo.png",
      title: "Kerala Police Housing and Construction Corp.",
    },
    {
      id: 4,
      img:"../images/kulogo2.jpg",
      title: "University Of Kerala",
    },
    {
      id: 2,
      img:"../images/habitatlogo.jpg",
      title: "Habitat Technology Group",
    },
    {
      id: 1,
      img:"../images/kollamlogo.jpg",
      title: "Corporation Civil Wing Kollam",
    },
  
   
    {
      id: 4,
      img:"../images/islamiclogo.webp",
      title: "Islamic Service Trust Kerala",
    },
    
    {
      id: 4,
      img:"../images/keralalogo.png",
      title: "Jilla Panchayat Alapuzha",
    },
    {
      id: 4,
      img:"../images/masjidlogo.webp",
      title: "Jumma Masjid Trivandrum",
    },
   
    
    
    
    
   
  ]

  export const social = [
    {
      icon: <Facebook />,
    },
    {
      icon: <Twitter />,
    },
    {
      icon: <Instagram />,
    },
    
  ]
  