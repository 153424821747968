import React from 'react'
import { clients } from '../data/data'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Title from '../common/Title' 


const Clients = () => {
 
 
  return (
    <>
    <div className="section">
    <div className="clients">
        <div className="container">
         <Title title="Our Clients" />
         <div className="clientscardcontainer">
        {clients.map((item,i)=>(
            <div className="clientcards">
            <Card sx={{ maxWidth: 345, height:220 }} style={{backgroundColor: "#379683"}}>
            <CardActionArea>
        <CardMedia
        className="media-img"
          component="img"
          height="160"
          image={item.img}
          alt="client"
        />
        <CardContent>
          <Typography gutterBottom variant="body2" component="div" >
            <div className="carddesc">
           {item.title}
           </div>
          </Typography>
          
        </CardContent>
      </CardActionArea>
    </Card>
            </div>
        ))}
        </div>
    </div>
    </div>
    </div>
    
    </>
  )
}

export default Clients