import React from 'react'

import Title from '../common/Title'
import { contact } from '../data/data'
import PermPhoneMsgSharpIcon from '@mui/icons-material/PermPhoneMsgSharp';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Contact = () => {
  return (
    <>
    <div className="contact">
        <div className="contacthero">
        <img src='../images/HERO/contacthero.webp'alt=''/>
            </div>
        
        <div className="container">
            <Title title="Contact Us" />
            <div className="info">
            <a href="tel:+919249544482">
                <PermPhoneMsgSharpIcon color='primary'fontSize='large'/>
                  <h1>By Phone</h1>
                  <p className='number'>+91 7736044483 <br/>

                  +91 9249544482</p>
                  </a>
                   <p>Monday to Friday 9:30 AM to 5:30 PM</p>
                   <a href="mailto:crescentcontractingcompany@gmail.com">
                   <AttachEmailIcon color='primary' fontSize='large'/>
                   <h1>By Email</h1>
                  <p className='number'>
                  crescentcontractingcompany@gmail.com</p>
                  </a>
                  <a href="mailto:crescentcctvm@gmail.com">
                  <p className='number'>crescentcctvm@gmail.com</p>
                  </a>
                  <LocationOnIcon color='primary' fontSize='large'/> 
                  <a href='https://www.google.com/maps/place/Crescent+Construction+And+Contracting+Company/@8.4826407,76.9275449,17z/data=!3m1!4b1!4m5!3m4!1s0x3b05bb77c3246205:0xa2de686dd590597f!8m2!3d8.4826407!4d76.9297336' alt=""
                  target="_blank"
                  >
                  <h1>Find us @Google Maps</h1>
                  <p className='address'>CRESCENT MANSION, KARALI ROAD,<br/> NEAR MALL OF TRAVANCORE,<br/> TRIVANDRUM, KERALA, INDIA 695008</p></a>




                </div>
        </div>
    </div>
    </>
  )
}

export default Contact