import React, { useEffect, useState } from 'react'
import { projects } from '../data/data'
import Title from '../common/Title'

const allCategory = ["featured","all", ...new Set(projects.map((item)=>item.category))]
const Projects = () => {

    const [list, setList] = useState(projects)
    const [category,setCategory] = useState(allCategory)
    const [toggleState, setToggleState] = useState(2);

const filterItems = (category,id) =>{
    setToggleState(id);
    if(category==="featured"){
        const newItems= projects.filter((item)=>item.featured ==="true")
        setList(newItems)
    }else{
    const newItems = projects.filter((item)=>item.category === category)
    setList(newItems)
    }


    if(category === "all"){
        setList(projects)
    }
}
useEffect(()=>{
    const newItems= projects.filter((item)=>item.featured ==="true")
        setList(newItems)
        setToggleState(1)
},[])


  return (
    <>
      <article>
        <div className='container'>
            <Title title="Our Projects" />
            <div className="catButton">
              
                <button className={ toggleState===1?"activecatbtn":"catbtn"} onClick={()=>filterItems("featured",1)} data-aos='zoom-out-down'>Featured</button>
                <button className={ toggleState===2?"activecatbtn":"catbtn"} onClick={()=>filterItems("all",2)} data-aos='zoom-out-down'>All</button>
                <button className={ toggleState===3?"activecatbtn":"catbtn"} onClick={()=>filterItems("Complete",3)} data-aos='zoom-out-down'>Complete</button>
                <button className={ toggleState===4?"activecatbtn":"catbtn"} onClick={()=>filterItems("ongoing",4)} data-aos='zoom-out-down'>Ongoing</button>
            </div>

        
        <div className="content grid3">
           {list.map((items)=>(
               <div className="box" data-aos='fade-up'>
                <div className="img">
                    <img src={items.cover} alt=''/>
                </div>
                <div className="overlay">
                    <h3>{items.title}</h3>
                    

                </div>
                <div className="description">
                    <h4>{items.title}</h4>
                </div>
               </div>
           )
            
           )}
           </div>
        </div>
      </article>
    
    </>
  )
}

export default Projects